import { setGlobalModal } from 'actions/index'

import { FEATURE_LIMIT_MODAL, NO_PLAN_MODAL } from 'constants/account'
import {
  SET_GATED_FEATURE,
  SET_FEATURE_LIMITS
} from 'constants/action_types/accounts/featureLimits'

export const triggerFeatureLimitModal = payload => async (
  dispatch,
  getState
) => {
  const { modal } = getState().globalData

  dispatch({
    type: SET_GATED_FEATURE,
    payload
  })

  if (modal !== FEATURE_LIMIT_MODAL && modal !== NO_PLAN_MODAL) {
    dispatch(setGlobalModal(FEATURE_LIMIT_MODAL))
  }
}

export const updateFeatureLimit = (
  feature,
  featureLimits
) => async dispatch => {
  dispatch({
    type: SET_FEATURE_LIMITS,
    payload: {
      feature,
      featureLimits
    }
  })
}
