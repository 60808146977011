/****** NOTE EVERYTHING IN THIS FILE MUST BE ES5 otherwise the production build fails ******/
if (!document.referrer) {
  Object.defineProperty(document, 'referrer', {
    value: 'https://www.junglescout.com/'
  })
}

// mock out stripe for running offline
if (process.env.REACT_APP_JUNGLE_ENV === 'test' && window && !window.Stripe) {
  window.Stripe = function() {
    return {
      elements: function() {
        return { create: function() {} }
      }
    }
  }
}
