import Api from 'services/api/accounts/amazonSellerAccounts'
import {
  PENDING_AMAZON_SELLER_ACCOUNTS_ACTION,
  SET_AMAZON_SELLER_ACCOUNTS_DATA
} from 'constants/action_types/amazon_seller_accounts'
import { setGetFeedbackCustomVariables } from 'services/getFeedback'

export const getAmazonSellerAccounts = () => async dispatch => {
  dispatch({ type: PENDING_AMAZON_SELLER_ACCOUNTS_ACTION })

  const res = await Api.getAmazonSellerAccounts()

  if (res.ok) {
    dispatch({
      type: SET_AMAZON_SELLER_ACCOUNTS_DATA,
      payload: {
        amazonSellerAccounts: res.data,
        sellerDataAvailable: true
      }
    })

    // set custom variables for GetFeedback/UsabillaLive
    const mwsKeysCount = res.data?.length || 0
    setGetFeedbackCustomVariables({ mwsKeysCount })
  } else {
    dispatch({
      type: SET_AMAZON_SELLER_ACCOUNTS_DATA,
      payload: {
        amazonSellerAccounts: [],
        sellerDataAvailable: false
      }
    })
  }
}
